import React, { useContext, useState } from 'react'
import Name from '../components/Name'
import Img from '../images/VPLAY.png'
import { AuthContext } from "../contexts/Authcontext";
import {Link, useNavigate } from 'react-router-dom';

function Signup() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')

  const navigate = useNavigate()

  const { CreateUser } = useContext(AuthContext);
  const handleCreateUser = async () => {
    try {
      if (password === repeatPassword) {
        await CreateUser(email, password);
        navigate('/')
      }
    } catch (error) {
      alert('Error creating user');
    }
  }
  return (
    <div>
      <form className='signup' onSubmit={(e) => e.preventDefault()}>
        <img className='signup-img' src={Img} alt="" srcset="" />
        <Name/>
        <div>
          <label className='email-label' htmlFor="email" >Email</label><br />
          <input className='email' type="email" name="" id="email" placeholder='@gmail.com' onChange={(e) => {
            setEmail(e.target.value)
            console.log(email);
          }}/>
        </div>
        <div>
          <label className='pass-label' htmlFor="password">Password</label> <br />
          <input className='pass' type="password" value={password} name="password" id="password" placeholder='password' onChange={(e) => setPassword(e.target.value)}/>
        </div>
        <div>
          <label className='pass-label' htmlFor="password"> Repeat Password</label> <br />
          <input className='pass' type="password" value={repeatPassword} name="password" id="password" placeholder='password' onChange={(e) => setRepeatPassword(e.target.value)}/>
        </div>
        <input className='submit' type="submit" value="Submit" onClick={handleCreateUser}  />
        <p>Have an account?.<Link to="/login"><span className='reg'>Signin</span></Link></p>
      </form>
    </div>
  )
}

export default Signup