import React from 'react'
import {RouterProvider, createBrowserRouter} from 'react-router-dom'
import Home from '../../src/pages/Home'
import Signup from '../pages/Signup'
import Signin from '../pages/login'
import Admin from '../../src/pages/Admin'
import Adminlogin from '../../src/pages/Adminlogin';
import ProtectedRoute from '../ProtectedRoute'




const pages = createBrowserRouter([
  {path:"/" ,element:<Home/>},
  {path:"/signup" ,element:<Signup/>},
  {path:"/login" ,element:<Signin/>},
  {path:"/admin" ,element:<ProtectedRoute><Admin/></ProtectedRoute>},
  {path:"/adminlogin" ,element:<Adminlogin/>}
])



function index() {
  return (
    
      <RouterProvider router={pages}/>
    
  )
}

export default index