import React from 'react'
import {  deleteDoc, doc } from "firebase/firestore";
import { db } from '../firebase';
export function AdminMusicGrid({id, musicImg,musicName, artistName, description, setCurrentMusic, musicFile}) {
  // console.log(musicImg);
  const handleClick = () => {
    setCurrentMusic({
      musicImg: musicImg, 
      musicName: musicName, 
      artistName: artistName, 
      musicFile: musicFile
  })
  }
  // const musicRef = collection(db, 'musicData')
  const handleDelete = () => {
    deleteDoc(doc(db,'musicData', id))
  }
  return (
    <div className='grid-contain'>

      <div className='grid'>
        <img src={musicImg} alt="asake" height={200}  width={200}/>
        <div className='discription'>
         <div>
          <p className='name'>{artistName}</p>
          <p className='tittle'>{musicName}</p>
         </div>
         <button onClick={handleClick}>play</button>
        </div>
        <p className='discribe'><span className='name'>Discription: {description}</span></p>
      </div>
      <button onClick={handleDelete} >X</button>
    </div>
  )
}
function musicGrid({musicImg,musicName, artistName, description, setCurrentMusic, musicFile}) {
  // console.log(musicImg);
  const handleClick = () => {
    setCurrentMusic({
      musicImg: musicImg, 
      musicName: musicName, 
      artistName: artistName, 
      musicFile: musicFile
  })
  }
  return (
    <div className='grid-contain'>

      <div className='grid'>
        <img src={musicImg} alt="asake" height={200}  width={200}/>
        <div className='discription'>
         <div>
          <p className='name'>{artistName}</p>
          <p className='tittle'>{musicName}</p>
         </div>
         <button onClick={handleClick}>play</button>
        </div>
        <p className='discribe'><span className='name'>Discription: {description}</span></p>
      </div>
    </div>
  )
}

export default musicGrid