// import React, { useState } from "react";
import { Timestamp, addDoc, collection } from "firebase/firestore";
import { storage, db } from "../firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import {AdminMusicGrid} from "../components/MusicGrid";
import Searchbar from '../components/Input'
import { onSnapshot, orderBy, query } from "firebase/firestore";
import { useState, useEffect } from "react";

function Admin() {
  const [searchQuery, setSearchQuery] = useState("");
  const [currentMusic, setCurrentMusic] = useState('')
  const [musicDetails, setMusicDetails] = useState([]);
  
  const filteredMusic = musicDetails.filter((video) =>
    video.musicName.toLowerCase().includes(searchQuery.toLowerCase())
  );
  useEffect(() => {
    const musicRef = collection(db, "musicData");
    const q = query(musicRef, orderBy("createdAt", "desc"));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const musicDetail = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setMusicDetails(musicDetail);
    });
    return () => unsubscribe(); // Cleanup function to unsubscribe from snapshot listener
  }, []);
  const [formData, setFormData] = useState({
    musicImg: null,
    musicFile: null,
    artistName: "",
    musicName: "",
    description: "",
    createdAt: Timestamp.now().toDate(),
  });
  const [progress, setProgress] = useState(0);
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleFileChange = (e, type) => {
    const file = e.target.files[0];
    setFormData({ ...formData, [type]: file });
  };
  function uploadMusic() {
    if (!formData.musicFile || !formData.musicImg || !formData.musicName) {
      alert("please fill all fields");
      return;
    }

    const musicRef = ref(
      storage,
      `uploadedMusic/${Date.now()}${formData.musicFile.name}`
    );
    const musicImgRef = ref(
      storage,
      `uploadedMusicImg/${Date.now()}${formData.musicImg.name}`
    );

    const musicUpload = uploadBytesResumable(musicRef, formData.musicFile);
    const musicImgUpload = uploadBytesResumable(musicImgRef, formData.musicImg);

    // Use Promise.all to wait for both upload tasks to complete
    Promise.all([musicUpload, musicImgUpload])
      .then(async ([musicSnapshot, musicImgSnapshot]) => {
        // Access music and image download URLs
        const downloadURL = await getDownloadURL(musicRef);
        const downloadImgURL = await getDownloadURL(musicImgRef);

        // Update Firestore with music details
        const uploadRef = collection(db, "musicData");
        await addDoc(uploadRef, {
          musicImg: downloadImgURL,
          artistName: formData.artistName,
          musicName: formData.musicName,
          musicFile: downloadURL,
          description:
            formData.description || "No Description Available for this Music",
          createdAt: Timestamp.now().toDate(),
        });

        // Reset form data and progress
        setFormData({
          musicImg: "",
          artistName: "",
          musicName: "",
          musicFile: "",
          description: "",
        });
        setProgress(0);

        alert("Music and image uploaded successfully");
      })
      .catch((error) => {
        console.error("Error during file upload:", error);
        alert("An error occurred during file upload");
      });
  }
  return (
    <div >
      <div className="admin-search">
      <Searchbar  setSearchQuery={setSearchQuery} searchQuery={searchQuery} />
      </div>

      <div className="main">
        <h1 className="admn">admin</h1>
        <form
          className="admin-form"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div>
          <label className="music-file" htmlFor="image">music image</label>
            <input
              type="file"
              accept="image/*"
              name="musicImg"
              onChange={(e) => handleFileChange(e, "musicImg")}
              // value={formData.musicImg ? formData.musicImg.name : ""}
            />

            <label className="music-file" htmlFor="music">Music file</label>
            <input
              type="file"
              name="musicFile"
              accept=".mp3"
              onChange={(e) => handleFileChange(e, "musicFile")}
              // value={formData.musicFile ? formData.musicFile.name : ""}
            />
          </div>
          <div className="text">
            
            <input
              type="text"
              name="artistName" 
              placeholder=" Artist Name"
              value={formData.artistName}
              onChange={(e) => handleChange(e)}
            />
            <input
              type="text"
              name="musicName"
              placeholder="Song Title"
              value={formData.musicName}
              onChange={(e) => handleChange(e)}
            />
            <textarea
              className="form-fill"
              rows="40"
              cols="50"
              name="description"
              placeholder="Description"
              value={formData.description}
              onChange={(e) => handleChange(e)}
            ></textarea>
          </div>
          {progress === 0 ? null : (
            <div
              className="m-1 text-white bg-purple-400 p-2 text-center rounded-lg"
              style={{ width: `${progress}%` }}
            >
              {progress}%
            </div>
          )}
          <button className="submit-btn" onClick={uploadMusic}>
            submit
          </button>
        </form>
        {currentMusic && ( // Check if currentMusic is not null or undefined
      <>
        <img className="play-img" src={currentMusic.musicImg} alt="tfk" />
        <h3 className="artist-name">{currentMusic.artistName}</h3>
        <h4 className="song">Title:{currentMusic.musicName}</h4>
        <div className="control"><audio src={currentMusic.musicFile} controls autoPlay></audio></div>
      </>
    )}
        <div className="selection">
          {
           (filteredMusic.length === 0 && 'No Music Found') || filteredMusic.map(({id, musicImg, musicFile, musicName, artistName, description}) => (
              <AdminMusicGrid
              key={id}
              id={id}
              musicImg={musicImg}
              musicFile={musicFile}
              artistName={artistName}
              musicName={musicName}
              description={description}
              setCurrentMusic={setCurrentMusic}
              />
           ))
          }
        </div>
        
      </div>
    </div>
  );
}

export default Admin;
