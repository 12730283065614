import React from 'react'
import Contact from '../images/icons/unnamed.png'
import Logo from '../images/VPLAY.png'
import { Link } from 'react-router-dom'

function Nav() {
  return (
    <div className='nav'>
        <img src={Logo} alt="logo" className='logo' />

        <div className='contact-con'>
          <img className='contact' src={Contact} alt="x" srcset="" />
        <p className='sign'><Link to="/login" className='signin-s'><span >sign in</span></Link>/<Link to="/signup" className='signin-s'><span>sign up</span></Link></p>
        </div>
    </div>
  )
}

export default Nav