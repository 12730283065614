import React, { useContext, useState } from 'react'
// import Img from '../images/artist-img/headset.jpg'
import { Link, useNavigate } from 'react-router-dom'
import { AuthContext } from '../contexts/Authcontext';
// import Admin from '../pages/Admin'


function Adminlogin() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const navigate = useNavigate()

  const {signin} = useContext(AuthContext)

  const handleLoginFunc = async () => {
    try {
     if (email === 'admin@VPLAY.com') {
      const res = await signin(email, password);
      console.log(res);
      navigate('/admin')
      alert('Login successful');
    } else {
       alert('Please provide a valid email');
        navigate('/')
     }
    } catch (error) {
     alert(error.message)
    }
 
   }

  return (
    <div>
        <form>
        
          <div className='login-con'>
            <label className='email-label' htmlFor="email" >Email</label><br />
            <input className='email' type="email" name="" id="email" onChange={(e) => setEmail(e.target.value)} placeholder='@gmail.com'/>
          </div>
          <div>
            <label className='pass-label' htmlFor="password">Password</label> <br/>
            <input className='pass' type="password" onChange={(e) => setPassword(e.target.value)} id="password" placeholder='password'/>
          </div>
          <Link to="/adminlogin">
          <input className='submit' type="submit" onClick={handleLoginFunc} value="Submit"/>
          </Link>
          
        </form>
        
    </div>
  )
}

export default Adminlogin