import React from 'react'

function Name() {
  return (
    <div>
      <label className='name-label' htmlFor="name">Name</label><br />
      <input className='input-name' type="text" name="name" id="name" placeholder='Name'/>
    </div>
  )
}

export default Name