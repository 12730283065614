import React from 'react'
import Search from '../images/icons/search-icon.png'

function Input({ setSearchQuery, searchQuery }) {
  return (
    <div className="search-div">
      <input
        type="search"
        name=""
        id=""
        placeholder="search"
        onChange={(e) => setSearchQuery(e.target.value)}
        className="search"
        value={searchQuery}
      />
      <img src={Search} alt="" srcset="" className="icon" />
    </div>
  );
}

export default Input