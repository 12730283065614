import React, { useState, useEffect } from "react";
import MusicGrid from "../../src/components/MusicGrid";
import Nav from "../../src/components/Nav";
import Searchbar from "../../src/components/Input";
import { db, auth } from "../firebase";
import News from "../../src/components/News";
import { collection, onSnapshot, orderBy, query, addDoc, Timestamp } from "firebase/firestore";
// import { CurrentMusic } from "../components/MusicGrid";

function Home() {
  const [musicDetails, setMusicDetails] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentMusic, setCurrentMusic] = useState('');
  const [comments, setComments] = useState('');
  const [allComments, setAllComments] = useState([]);

  function getCurrentYear() {
    return new Date().getFullYear();
}

// Function to update the copyright notice
function updateCopyright() {
    var year = getCurrentYear();
    var copyrightElement = document.getElementById("copyright");
    copyrightElement.textContent = "Copyright © " + year + " VPLAY";
}

// Call the function on page load
window.onload = updateCopyright;



  

  useEffect(() => {
    const musicRef = collection(db, "musicData");
    const q = query(musicRef, orderBy("createdAt", "desc"));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const musicDetail = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setMusicDetails(musicDetail);
    });
    return () => unsubscribe(); // Cleanup function to unsubscribe from snapshot listener
  }, []); 
  const filteredMusic = musicDetails.filter((video) =>
    video.musicName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleCommentSubmit = () =>{
    const commentRef = collection(db, 'comments');
    addDoc(commentRef, {
      comment: comments,
      user: auth.currentUser.email || 'Unknown',
      createdAt: Timestamp.now().toDate(),
    })
    setComments('')
    alert('Commented')
  }
  useEffect(() => {
    const commentRef = collection(db, 'comments');
    const commentQuery = query(commentRef, 'comments', orderBy('createdAt', 'desc'));
    const unsubscribe = onSnapshot(commentQuery, (snapshot) => {
      const allComment = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }));
      setAllComments(allComment);
      // console.log(allComments);
    })
    return () => unsubscribe();
  },[])
  // console.log(currentMusic);
  return (
    <div>
      <header className="App-header">
        <Nav />
        <Searchbar setSearchQuery={setSearchQuery} searchQuery={searchQuery} />
      </header>

      <div className="grid-section">
        <div className="play">
        <div className="onPLay">
        {currentMusic && ( // Check if currentMusic is not null or undefined
      <>
        <img className="play-img" src={currentMusic.musicImg} alt="tfk" />
        <h3 className="artist-name">{currentMusic.artistName}</h3>
        <h4 className="song">Title:{currentMusic.musicName}</h4>
        <div className="control"><audio src={currentMusic.musicFile} controls autoPlay></audio></div>
      </>
    )}
    </div>
          <div className="comment-con">
             <div>
             <h3>comment</h3>
             <input type="text" value={comments} id="" onChange={(e) => setComments(e.target.value)} onKeyDown={(e) => e.key === 'Enter' ? handleCommentSubmit() : null}  placeholder="type your comment"/>
             </div>

             <div className="comment-display">
               {
                 allComments.map((comment) => {
                   return (
                     <div className="comment-display">
                       <h4 className="commenter-name">{comment.user.slice(0, 4)}*******</h4>
                       <p>{comment.comment}</p>
                       <hr />
                     </div>
                   )
                 })
               }
             </div>
          </div>
        </div>
        <div className="selection">
          {
           (filteredMusic.length === 0 && 'No Music Found') || filteredMusic.map(({id, musicImg, musicFile, musicName, artistName, description}) => (
              <MusicGrid
              key={id}
              musicImg={musicImg}
              musicFile={musicFile}
              artistName={artistName}
              musicName={musicName}
              description={description}
              setCurrentMusic={setCurrentMusic}
              />
           ))
          }
        </div>
      </div>

      <News />

      <p id="copyright" ></p>
    </div>
  );
}

export default Home;
