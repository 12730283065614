import React,{ useState, useContext} from 'react'
import Img from '../images/VPLAY.png'
import { AuthContext } from "../contexts/Authcontext";
import { Link, useNavigate } from 'react-router-dom';



function Login() {

  const navigate = useNavigate()

  const {signin} = useContext(AuthContext)
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const handleLoginFunc = async () => {
   try {
    const res = await signin(email, password)
    console.log(res);
    navigate('/')
    alert('Login successful')
   } catch (error) {
    alert(error.message)
   }

  } 
  return (
    <div className='login'>
       <form>
        <img className='vplay' src={Img} alt="" srcset="" />
        <div>
          <label className='email-label' htmlFor="email" >Email</label><br />
          <input className='email' type="email" name="" id="email" placeholder='@gmail.com' onChange={(e) => {
              setEmail(e.target.value)
              console.log(email);
            }}/>
        </div>
        <div>
          <label className='pass-label' htmlFor="password">Password</label> <br/>
          <input className='pass' type="password" value={password} name="password" id="password" placeholder='password' onChange={(e) => setPassword(e.target.value)}/>
        </div>
        <input className='submit' type="submit" value="Submit" onClick={handleLoginFunc}  />
        <p>Don't have an account?.<Link to="/signup"><span className='reg'>Signup</span></Link></p>
       </form>

    </div>
  )
}

export default Login