import './App.css';
import Base from '../src/router/index'
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { AuthContext } from './contexts/Authcontext';
import { auth, db } from './firebase';
import { useEffect, useState } from 'react';
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";


function App() {
  const [musicDetails, setMusicDetails] = useState()
  const CreateUser = (email, password) => {
    createUserWithEmailAndPassword(auth, email, password); return
  }
  const signin = (email, password) => {
    signInWithEmailAndPassword(auth, email, password)
  }
  useEffect(() => {
  const musicDetailsRef = collection(db, "musicData");
    const q = query(musicDetailsRef, orderBy("createdAt", "desc"));
    onSnapshot(q, (snapshot) => {
      const musicDetail = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setMusicDetails(musicDetail);
    });
  }, []);
  return (
    <>
      <AuthContext.Provider value={{CreateUser, signin, musicDetails}}>
        <Base />
      </AuthContext.Provider>
      
    </>
  );
}

export default App;
