import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCSMZuxN2cXOylA6vOnlANNoz4jSvGgLKc",
  authDomain: "music-app-78929.firebaseapp.com",
  projectId: "music-app-78929",
  storageBucket: "music-app-78929.appspot.com",
  messagingSenderId: "131357741588",
  appId: "1:131357741588:web:a67ffd3b8d97401d784d01",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const storage = getStorage(app);
export const db = getFirestore(app);
