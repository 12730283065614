import React from 'react'
import First from '../images/news-image/Davido.webp'
import Second from '../images/news-image/rema.jpeg'

function News() {
  return (
    <div className='news-container'>
      <h1 className='topic'> Latest News</h1>
      <div className='news-bar'>
        <img src={First} alt="" srcset="" />
        <div className="">
          <h4 className='headline'>Davido in Trouble</h4>
          <p>
          Popular Nigerian singer and songwriter, Tiwa Savage has filed a petition against Afrobeats star, David Adeleke, popularly known as Davido, over an alleged threat to her life.
          In a petition addressed to the Lagos State Commissioner of Police, Fayoade Adegoke, Tiwa Savage alleged Davido made a “direct threat to my rights to life, privacy and dignity of my person”.
          The singer explained that the event may not be unconnected to her relationship with Sophia Momodu, Davido’s baby mama, whom Davido is reportedly not in good terms with. 
          The petition read, “I am writing to bring to your attention a distressing situation involving myself, Tiwatope Omolara Savage, popularly known as Tiwa Savage, and Mr David Adeleke popularly known as Davido.
          “On the 23rd of December, I made a post which included myself and Sophía Momodu on my Instagram Story- a feature on Instagram which allows you to put up a post for twenty-four hours, and Mr David Adeleke sent messages to my manager who’s also part of his management team that I shouldn’t worry and should never say a word to him and that I was taunting him, uttering very disrespectful, malicious and derogatory words to my person.
          “I responded to MR. DAVID ADELEKE’S message and told him not to reach out to me especially as I felt like I had been there for him and his daughter, Imade, and more importantly when he lost his son.
          “The events of that day have also led to a barrage of calls and messages from our mutual connections, asking me what 1 did to Mr David Adeleke and that he’s upset that I chose to maintain a relationship with Imade’s mother, Sophia Momodu who he’s having issues with and also painting the exchange to look like I was the aggressor. He has also told our mutual connections to warn me to “be careful in Lagos because he was going to f”k me up”.
          “This is beginning to feel like some form of online and offline harassment not just to me but also members of my team.
          </p>
        </div>
      </div>


      <div className='news-bar2'>
        <img src={Second} alt="" srcset="" />
        <div className="second-news">
          <h4 className='headline'>Rema's 'Calm Down' to the world</h4>
          <p>Mavin had already established a relationship with UMG, with Rema’s “Calm Down” — the biggest Afrobeats song of all time, which reached No. 3 on the Hot 100 last year — initially distributed by Virgin Music, with its remix featuring Selena Gomez licensed to Interscope; Starr, meanwhile, has a deal in place with Republic. “Calm Down” has racked up more than 1 billion on-demand streams in the U.S. alone since its release, with its global count many multiples higher.The investment is designed to spur Mavin’s growth around the world, according to a press release, with focuses on both Mavin’s Artist Academy, which nurtures its roster in various musical and performance skills, and its executive leadership team, which is aimed at growing the next generation of African leaders in the music business.
          “With our proven history of collaborations within the UMG family, we have a strong belief that they are the ideal partner for the next phase of our growth, given the diversity and potential of our business,” Oghenejobo said in a statement. “UMG is home to some of the world’s foremost music entrepreneurs and artists, making them a perfect match for our aspirations. By collaborating with UMG, we are dedicated to cultivating a vibrant creative environment that propels African music to new heights on the global stage.</p>
        </div>
      </div>
    </div>
  )
}

export default News